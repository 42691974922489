// import classes from '../../css/Images.module.css';
import Badge from './Badge';

const Download = (props) => {
  const printBadges = props.downloadInformation.map((app) => (
    <Badge key={app.qr} os={app.os} link={app.link} qr={app.qr} />
  ));
  return <div style={{ width: '100%' }}>{printBadges}</div>;
};

export default Download;
