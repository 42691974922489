import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header3d from '../components/details/Header3d';
import Description from '../components/details/Description';
import Images from '../components/details/Images';
import Download from '../components/details/Download';
import { TreDData } from '../data/TreDData';
import DefaultBackdrop from '../components/DefaultBackdrop';

const SpecificAppPage = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [selector, setSelector] = useState('Beskrivning');

  const app = TreDData.find((app) => app.route === params.treD);

  document.querySelector('title').textContent = `Mullog - ${app.name}`;

  let section = (
    <Images
      folder={app.route}
      images={app.screenshots}
      type="3d"
      orientation={app.orientation}
    />
  );

  return (
    <DefaultBackdrop showBackButton={true}>
      <Header3d name={app.name} icon={app.icon} />
      {section}
    </DefaultBackdrop>
  );
};

export default SpecificAppPage;
