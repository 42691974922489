import classes from '../../css/Header.module.css';
import SelectorButton from './SelectorButton';

const Header = (props) => {
  return (
    <div className={classes.mainContainer}>
      <img className={classes.image} src={props.icon} alt="Ikon" />
      <div className={classes.container}>
        <h1 className={classes.header}>{props.name}</h1>
        <div className={classes.slectorContainer}>
          <SelectorButton
            name="Beskrivning"
            isSelected={props.isSelected}
            onChangeSelector={props.onChangeSelector}
          />
          <SelectorButton
            name="Bilder"
            isSelected={props.isSelected}
            onChangeSelector={props.onChangeSelector}
          />
          <SelectorButton
            name="Ladda ner"
            isSelected={props.isSelected}
            onChangeSelector={props.onChangeSelector}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
