import classes from '../css/Icon.module.css';
import { Link } from 'react-router-dom';

const Icon = (props) => {
  return (
    <div className={classes.container}>
      <Link to={`${props.route}`}>
        <img className={classes.image} src={props.image} alt="Ikon" />
      </Link>
      <Link className={classes.link} to={`${props.route}`}>
        <h1>{props.name}</h1>
      </Link>
    </div>
  );
};

export default Icon;
