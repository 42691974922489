import { Fragment } from 'react';
import classes from '../../css/Header.module.css';
import SelectorButton from './SelectorButton';

const Header = (props) => {
  return (
    <Fragment>
      <div className={classes.mainContainer}>
        <img className={classes.image} src={props.icon} alt="Ikon" />
        <div className={classes.container}>
          <h1 className={classes.header}>{props.name}</h1>
        </div>
      </div>
      <div className={classes.line} />
    </Fragment>
  );
};

export default Header;
