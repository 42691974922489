import classes from '../css/Navigation.module.css';
import { NavigationLinksData } from '../data/NavigationLinksData';
import { NavLink } from 'react-router-dom';

const Navigation = (props) => {
  const link = NavigationLinksData.map((link) => (
    <li className={classes.navLi} key={link}>
      <NavLink
        className={(navData) =>
          link === 'HEM'
            ? classes.navLiA
            : navData.isActive
            ? `${classes.navLiA} ${classes.active}`
            : classes.navLiA
        }
        // activeClassName={link !== 'HEM' ? classes.active : ''}
        to={link !== 'HEM' ? `/${link.toLowerCase()}` : '/'}
      >
        {link}
      </NavLink>
    </li>
  ));

  return (
    <div className={classes.container}>
      <ul className={classes.navUl}>{link}</ul>
    </div>
  );
};

export default Navigation;
