import classes from '../../css/Badge.module.css';
import badgeApple from '../../assets/images/badge_apple.png';
import badgeGoogle from '../../assets/images/badge_google.png';

const Badge = (props) => {
  let badge = (
    <a href={props.link} target="_blank" rel="noreferrer">
      <img
        className={classes.image}
        src={badgeApple}
        alt="Download on the App Store"
      />
    </a>
  );
  let qr = (
    <img className={classes.qr} src={props.qr} alt="Qr code App Store" />
  );

  if (props.os === 'android') {
    badge = (
      <a href={props.link} target="_blank" rel="noreferrer">
        <img
          className={classes.image}
          src={badgeGoogle}
          alt="Get in on Google Play"
        />
      </a>
    );
    qr = (
      <img className={classes.qr} src={props.qr} alt="Qr code Google Play" />
    );
  }

  return (
    <div className={classes.container}>
      {badge}
      {qr}
    </div>
  );
};

export default Badge;
