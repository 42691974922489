import classes from '../css/DefaultBackdrop.module.css';
import BackButton from './BackButton';

const DefaultBackdrop = (props) => {
  return (
    <div className={classes.all}>
      {props.showBackButton === true ? <BackButton /> : null}
      <div className={classes.container}>{props.children}</div>
    </div>
  );
};

export default DefaultBackdrop;
