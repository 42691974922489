import { Fragment } from 'react';

export const AppsData = [
  {
    id: '1',
    name: 'Scorecard',
    route: 'scorecard',
    icon: '/images/apps/scorecard/icon_scorecard_small.png',
    description: (
      <Fragment>
        <p>
          Are you tired of having to carry around a pen and paper when you want
          to play?
          <br />
          Is it always someone trying to cheat when you count the scores?
          <br />
          Do you often forget how many strokes/throw you've used?
          <br />
          Then this is the app you have been waiting for!
        </p>
        <ul>
          <li>Up to five players can play simultaneously.</li>
          <li>Choose either 9 or 18 holes.</li>
          <li>Choose whether you want to use par or not.</li>
          <li>Choose to play with points using your handicap.</li>
          <li>
            Use the stroke counter if you want to easily keep track of your
            strokes.
          </li>
          <li>
            Save up to five of your favorite courses (ten in Pro-version).
          </li>
        </ul>
      </Fragment>
    ),
    screenshots: ['1', '2', '3', '4', '5'],
    orientation: 'portrait',
    downloadInformation: [
      {
        os: 'ios',
        link: 'https://apps.apple.com/us/app/golf-discgolf-scorecard-lite/id696967681',
        qr: '/images/apps/scorecard/qr-ios.png',
      },
      {
        os: 'android',
        link: 'https://play.google.com/store/apps/details?id=net.mullog.scorecardfree&hl=en&gl=US',
        qr: '/images/apps/scorecard/qr-android.png',
      },
    ],
  },
  {
    id: '2',
    name: 'Score counter',
    route: 'score-counter',
    icon: '/images/apps/score-counter/icon_scorecounter_small.png',
    description: (
      <p>
        Thanks to this app you no longer need to look for a pen and paper every
        time you want to play a game and count the score. With this app you have
        full control over all players scores and can easily add or remove any
        points from any player at anytime. Now you won't have any trouble with
        ink running out or pens breaking. Play up to five people on one single
        device (seven on iPad).
      </p>
    ),
    screenshots: ['1', '2', '3', '4', '5'],
    orientation: 'landscape',
    downloadInformation: [
      {
        os: 'ios',
        link: 'https://apps.apple.com/us/app/score-counter-for-iphone/id1018667503',
        qr: '/images/apps/score-counter/qr-ios.png',
      },
      {
        os: 'android',
        link: 'https://play.google.com/store/apps/details?id=net.mullog.scorecounterfree&hl=en&gl=US',
        qr: '/images/apps/score-counter/qr-android.png',
      },
    ],
  },
  {
    id: '3',
    name: 'Dices',
    route: 'dices',
    icon: '/images/apps/dices/icon_dices_small.png',
    description: (
      <Fragment>
        <p>
          Have you lost or forgotten your dices?
          <br />
          Do you think it's more fun to use digital ones than real ones?
          <br />
          No matter what, this app is definitely perfect for you.
          <br />
          With it's great design and great functions you won't be disappointed.
        </p>

        <h3>Dice types:</h3>
        <p>You can choose on 9 different dice types.</p>
        <ul>
          <li>A coin, to get 50/50 results</li>
          <li>d4 dice, 1-4</li>
          <li>d6 dice, 1-6 with dots (classic dice)</li>
          <li>d6 dice, 1-6 with numbers</li>
          <li>d8 dice, 1-8</li>
          <li>d10 dice, 0-9</li>
          <li>d10 dice, 00-90</li>
          <li>d12 dice, 1-12</li>
          <li>d20 dice, 1-20</li>
        </ul>

        <h3>Color:</h3>
        <p>
          You can choose among five different colours. White, red, green, blue
          and yellow. 16,777,216 different colors in Pro-version thanks to the
          RGB-slider.
        </p>

        <h3>Functions:</h3>
        <ul>
          <li>By pressing a dice you will lock it.</li>
          <li>You can move them around the screen.</li>
          <li>
            You can remove the dices you don't want and bring them back later.
          </li>
          <li>Count the sum of all dices, the locked and unlocked.</li>
        </ul>
      </Fragment>
    ),
    screenshots: ['1', '2', '3', '4', '5', '6', '7'],
    orientation: 'landscape',
    downloadInformation: [
      {
        os: 'ios',
        link: 'https://apps.apple.com/us/app/my-dices-lite/id1451643176',
        qr: '/images/apps/dices/qr-ios.png',
      },
      {
        os: 'android',
        link: 'https://play.google.com/store/apps/details?id=net.mullog.diceslite&hl=en&gl=US',
        qr: '/images/apps/dices/qr-android.png',
      },
    ],
  },
  {
    id: '4',
    name: 'Golfskoven',
    route: 'golfskoven',
    icon: '/images/apps/golfskoven/icon_golfskoven_small.png',
    description: (
      <p>
        Denna app är gjord åt ett danskt företag,{' '}
        <a
          style={{ color: 'white' }}
          href="https://golfskoven.dk"
          target="_blank"
          rel="noreferrer"
        >
          Golfskoven.dk
        </a>
        <br />
        <br />
        Med vores scorecard kan du nemt og bekvemt holde styr på spillets gang i
        Golfskoven.
        <br />
        <br />
        Du kan registrere op til 6 spillere og holde styr på hvor mange slag I
        hver især bruger på de 18 golfhuller.
        <br />
        <br />
        Indbygget "slag-tæller" hjælper med at huske hvor mange slag der bliver
        brugt på det aktuelle hul.
        <br />
        <br />
        Undervejs i spillet kan du klikke på de forskellige hul numre på dit
        scorecard, og se et kort over golfhullet. På kortet har du visning af en
        nem og en knap så nem vej til flaget.
        <br />
        <br />
        Aftal inden spilstart om I spiller nem eller knap så nem udgave af
        spillet.
        <br />
        <br />
        Når I har været banen igennem, kan du gemme spillet. På den måde har du
        det endegyldige bevis på dine færdigheder eller et vink med en vognstang
        om at der er plads til forbedring næste gang I spiller...
        <br />
        <br />
        På resultatlisten kan I se jeres indbyrdes placeringer, hvor mange slag
        I hver især har brugt, og hvor mange slag over eller under banens par, I
        hver især har brugt.
        <br />
        <br />
        Husk altid at få sejren foreviget på vores sejrs tribune, når I har
        fundet en vinder. Spiller I 4 personer er der jo altid en fjerdeplads,
        der passende kan tage et vinderfoto af de 3 bedste...
        <br />
        <br />
        Hver måned trækker vi lod mellem de fotos der uploades på vores
        Instagram profil. Præmien er en runde adventure golf for 4 personer samt
        sandwich og øl eller sodavand til alle 4.
        <br />
        <br />
        Held og lykke!!
        <br />
        <br />
        Det er helt ude i skoven...
        <br />
        <br />
      </p>
    ),
    screenshots: ['1', '2', '3', '4', '5'],
    orientation: 'portrait',
    downloadInformation: [
      {
        os: 'ios',
        link: 'https://apps.apple.com/us/app/golfskoven/id1500761946',
        qr: '/images/apps/golfskoven/qr-ios.png',
      },
      {
        os: 'android',
        link: 'https://play.google.com/store/apps/details?id=dk.golfskoven.golfskoven&hl=en&gl=US',
        qr: '/images/apps/golfskoven/qr-android.png',
      },
    ],
  },
];
