import classes from '../../css/SelectorButton.module.css';

const SelectorButton = (props) => {
  const changeSelector = () => {
    props.onChangeSelector(props.name);
  };
  return (
    <div
      onClick={changeSelector}
      className={`${classes.style} ${
        props.name === props.isSelected ? classes.selected : classes.unselected
      }`}
    >
      <p className={classes.text}>{props.name}</p>
    </div>
  );
};

export default SelectorButton;
