import classes from '../css/AboutPage.module.css';
import DefaultBackdrop from '../components/DefaultBackdrop';
import classesText from '../css/Description.module.css';
import { KnowledgeIconsData } from '../data/KnowledgeIconsData';

const AboutPage = (props) => {
  document.querySelector('title').textContent = 'Mullog - Om';
  const allImages = KnowledgeIconsData.map((image) => (
    <img
      className={classes.image}
      key={image.alt}
      src={image.src}
      alt={image.alt}
    />
  ));
  return (
    <DefaultBackdrop>
      <p className={classesText.textContact}>
        Mullog är en webbutvecklare i närheten av Borås.
      </p>
      <h1>Mina kunskaper:</h1>
      <div className={classes.container}>{allImages}</div>
    </DefaultBackdrop>
  );
};

export default AboutPage;
