export const KnowledgeIconsData = [
  { src: '/images/icons/javascript.png', alt: 'Javascript' },
  { src: '/images/icons/c-sharp.png', alt: 'C-sharp' },
  { src: '/images/icons/react.png', alt: 'React' },
  { src: '/images/icons/react-native.png', alt: 'React Native' },
  { src: '/images/icons/html.png', alt: 'Html' },
  { src: '/images/icons/css.png', alt: 'Css' },
  { src: '/images/icons/photoshop.png', alt: 'Photoshop' },
  { src: '/images/icons/maya.png', alt: 'Maya' },
  { src: '/images/icons/java.png', alt: 'Java' },
];
