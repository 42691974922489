// import classes from '/ContactPage.module.css';
import DefaultBackdrop from '../components/DefaultBackdrop';
import classes from '../css/Description.module.css';

const ContactPage = (props) => {
  document.querySelector('title').textContent = 'Mullog - Kontakt';
  return (
    <DefaultBackdrop>
      <p className={classes.textContact}>
        If you have any questions or just want to come in contact, send a mail
        to info[a]mullog.net.
      </p>
    </DefaultBackdrop>
  );
};

export default ContactPage;
