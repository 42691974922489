import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import AppsPage from './pages/AppsPage';
import SpecificAppPage from './pages/SpecificAppPage';
import Specific3dPage from './pages/Specific3dPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import StartPage from './pages/StartPage';
import './App.css';

function App() {
  return (
    <Fragment>
      <Navigation />
      <Routes>
        <Route path="/appar" element={<AppsPage />} />
        <Route path="/3d" element={<AppsPage />} />
        <Route path="/om" element={<AboutPage />} />
        <Route path="/kontakt" element={<ContactPage />} />
        <Route path="/" element={<StartPage />} />

        <Route path="/appar/:appName" element={<SpecificAppPage />} />
        <Route path="/3d/:treD" element={<Specific3dPage />} />
      </Routes>
    </Fragment>
  );
}

export default App;
