import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/details/Header';
import Description from '../components/details/Description';
import Images from '../components/details/Images';
import Download from '../components/details/Download';
import { AppsData } from '../data/AppsData';
import DefaultBackdrop from '../components/DefaultBackdrop';

const SpecificAppPage = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [selector, setSelector] = useState('Beskrivning');

  const app = AppsData.find((app) => app.route === params.appName);

  document.querySelector('title').textContent = `Mullog - ${app.name}`;

  const changeSelector = (text) => {
    setSelector(text);
  };

  let section = <Description>{app.description}</Description>;
  if (selector === 'Bilder') {
    section = (
      <Images
        folder={app.route}
        images={app.screenshots}
        orientation={app.orientation}
        type="apps"
      />
    );
  }
  if (selector === 'Ladda ner') {
    section = <Download downloadInformation={app.downloadInformation} />;
  }

  return (
    <DefaultBackdrop showBackButton={true}>
      <Header
        name={app.name}
        icon={app.icon}
        isSelected={selector}
        onChangeSelector={changeSelector}
      />
      {section}
    </DefaultBackdrop>
  );
};

export default SpecificAppPage;
