import classes from '../../css/Images.module.css';
import Screenshot from './Screenshot';

const Images = (props) => {
  let images = props.images.map((image) => (
    <Screenshot
      key={image}
      imageDirectory={`/images/${props.type}/${props.folder}/${image}_small.png`}
      orientation={props.orientation}
    />
  ));
  return <div className={classes.container}>{images}</div>;
};

export default Images;
