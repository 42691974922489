import { Fragment } from 'react';

export const TreDData = [
  {
    id: '1',
    name: 'Kök 1',
    route: 'kitchen-1',
    icon: '/images/3d/kitchen-1/icon_kitchen_1.png',
    screenshots: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
    ],
    orientation: 'portrait',
  },
  {
    id: '2',
    name: 'Kök 2',
    route: 'kitchen-2',
    icon: '/images/3d/kitchen-2/icon_kitchen_2.png',
    screenshots: ['1', '2'],
    orientation: 'portrait',
  },
  {
    id: '3',
    name: 'Nintendo DS',
    route: 'nintendo-ds',
    icon: '/images/3d/nintendo-ds/icon_nintendo_ds.png',
    screenshots: ['1', '2'],
    orientation: 'portrait',
  },
];
