import { Fragment } from 'react';
// import classes from '/StartPage.module.css';

const StartPage = (props) => {
  document.querySelector('title').textContent = 'Mullog';
  return (
    <Fragment>
      {/* <p style={{ color: 'white' }}>StartPage komponent</p> */}
    </Fragment>
  );
};

export default StartPage;
