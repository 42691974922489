import classes from '../../css/Screenshot.module.css';

const Screenshot = (props) => {
  return (
    <div>
      <a href={props.link} target="_blank" rel="noreferrer">
        <img
          className={`${classes.main}
            ${
              props.orientation === 'portrait'
                ? classes.portrait
                : classes.landscape
            }`}
          src={props.imageDirectory}
          alt="Bild"
        />
      </a>
      {/* <img src={'/images/scorecard/2_small.png'} /> */}
    </div>
  );
};

export default Screenshot;
