import classes from '../css/BackButton.module.css';
import { useNavigate } from 'react-router-dom';

const BackButton = (props) => {
  const navigate = useNavigate();

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <button className={classes.style} onClick={goBackHandler}>
      Tillbaka
    </button>
  );
};

export default BackButton;
