import { useLocation } from 'react-router-dom';
import classes from '../css/AppsPage.module.css';
import Icon from '../components/Icon';
import { AppsData } from '../data/AppsData';
import { TreDData } from '../data/TreDData';

const AppsPage = (props) => {
  const location = useLocation();
  let dataInformation = null;
  let allApps = null;

  switch (location.pathname) {
    case '/3d':
      dataInformation = TreDData;
      document.querySelector('title').textContent = 'Mullog - 3D';
      allApps = dataInformation.map((app) => (
        <Icon key={app.id} name={app.name} route={app.route} image={app.icon} />
      ));
      break;

    default:
      dataInformation = AppsData;
      document.querySelector('title').textContent = 'Mullog - Appar';
      allApps = dataInformation.map((app) => (
        <Icon key={app.id} name={app.name} route={app.route} image={app.icon} />
      ));
      break;
  }

  return <div className={classes.container}>{allApps}</div>;
};

export default AppsPage;
