import classes from '../../css/Description.module.css';

const Description = (props) => {
  return (
    <div className={classes.container}>
      {/* <p className={classes.text}>{props.children}</p> */}
      <main className={classes.text}>{props.children}</main>
      {/* {props.children} */}
    </div>
  );
};

export default Description;
